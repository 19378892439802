.playerList {
    position: fixed;
    top: 50px;
    right: 0px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;
    gap: 2vmin;
    --player-list-max-height: 9.7vmin;
    --player-list-item-stats-height: calc(0.8 * var(--player-list-max-height));
    --player-list-item-stats-width: calc(1.8 * var(--player-list-max-height));
    --player-list-item-turn-size: calc(0.3 * var(--player-list-max-height));
}

.playerListItemContainer {
    filter: drop-shadow(-3px 3px 3px gray);
    position: relative;
    max-height: var(--player-list-max-height);
}

.playerListItemAvatar {
    --player-list-avatar-size: calc(0.8 * var(--player-list-max-height));
    max-width: var(--player-list-avatar-size);
    max-height: var(--player-list-avatar-size);
    object-fit: cover;
    filter: drop-shadow(-3px 3px 3px gray);
}

.playerListItemAvatarBackground {
    position: absolute;
    right: 0px;
    height: var(--player-list-item-stats-height);
    width: var(--player-list-item-stats-width);
    bottom: calc(0.5 * (var(--player-list-max-height) - var(--player-list-item-stats-height)));
    z-index: -2;
}

.playerListItemAvatarContainer,
.playerListItemAvatarBorder {
    border-radius: 50%;
    width: var(--player-list-max-height);
    height: var(--player-list-max-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.playerListItemAvatarBorder {
    border-width: 0.5vmin;
    border-color: white;
    border-style: solid;
    position: absolute;
    pointer-events: none;
}

.playerListItemAvatarName {
    position: absolute;
    bottom: 0px;
    transform: translateY(60%);
    font-weight: bold;
    max-width: calc(var(--player-list-item-stats-width) + 0.5 * var(--player-list-max-height));
    right: 0px;
}

.playerListItemStats {
    --player-list-item-stats-border-radius: calc(0.5 * var(--player-list-item-stats-height));

    --player-list-item-font-size: calc(0.25 * var(--player-list-item-stats-height));
    font-size: var(--player-list-item-font-size);

    height: var(--player-list-item-stats-height);
    width: var(--player-list-item-stats-width);
    background-color: #112732;
    right: calc(0.5 * var(--player-list-max-height));
    position: absolute;
    bottom: calc(0.5 * (var(--player-list-max-height) - var(--player-list-item-stats-height)));
    border-radius: var(--player-list-item-stats-border-radius);
    border-width: 0.5vmin;
    border-color: white;
    border-style: solid;
    z-index: -1;
}

.playerListItemStatsContainer {
    position: absolute;
    max-width: calc(var(--player-list-item-stats-width) - 0.5 * var(--player-list-item-stats-height));
    left: calc(0.5 * var(--player-list-item-stats-border-radius));
    top: 50%;
    transform: translateY(-50%);

    text-overflow: ellipsis;
    white-space: nowrap;
    display: table;
    border-spacing: calc(0.05 * var(--player-list-item-stats-height));
}

.playerListItem {
    width: 12vw;
    border: 3px solid white;
    border-radius: 5px;
    color: white;

    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: black;
    -webkit-text-fill-color: white;
    text-shadow: 0px 0px 5px black;
    font-weight: bold;

    --player-list-item-font-size: calc(0.4 * var(--player-list-item-stats-height));
    font-size: var(--player-list-item-font-size);

    display: flex;
    flex-direction: column;

    background-color: #456;
}

.playerListItemName {
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.playerListItemStatsRow {
    display: table-row;
}

.playerListItemStatsCell {
    display: table-cell !important;
    color: white;
}

.playerListItemTurn {
    width: var(--player-list-item-turn-size);
    height: var(--player-list-item-turn-size);
    background-color: #ffc0a0;
    border: 3px solid white;
    position: absolute;
    transform-origin: center;
    transform: translate(50%, -50%) rotate(45deg);
    right: 0px;
    top: 50%;
    animation-duration: 0.25s;
    animation-name: turnAnimation;
}

@keyframes turnAnimation {
    from {
        right: calc(-1 * var(--player-list-item-turn-size));
    }

    to {
        right: 0px;
    }
}

.playerListItemStatsIcon,
.playerListItemStatsIcon>svg {
    width: var(--player-list-item-font-size);
    height: var(--player-list-item-font-size);
}

.playerListItemAvatarForeground {
    position: relative;
    transition: margin-right 0.25s ease-out;
}

.playerListItemAvatarForegroundTurn {
    margin-right: calc(0.8 * var(--player-list-item-turn-size));
}

.playerListIconAssassin {
    position: absolute;
    bottom: 0px;
    transform: translateY(60%);
    font-weight: bold;
}

.playerListIconTeam {
    position: absolute;
    right: var(--player-list-item-stats-width);
}

.playerListItemPresenceIndicator {
    display: inline;
    padding-left: 5px;
}

.tagContainer {
    display: flex;
}

.tagName {
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

.chatBubble,
.chatBubble div {
    border-radius: 24px !important;
    max-width: 33vw;
}

.chatPopupsContainer {
    position: absolute;
    right: var(--player-list-avatar-size);
}

.chatBubbleContent {
    padding: 20px;
    border-radius: 25%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.playerListItemButton {
    position: absolute;
    left: -16px;
}

.playerListItemStatsIconCardsHasSet {
    font-weight: bold;
    color: lime;
}
