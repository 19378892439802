.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.name {
    font-size: large;
    font-weight: bold;
    margin-bottom: 10px;
}

.avatar {
    max-height: 64px;
}
