.settingsContent {
    display: table;
    border-spacing: 10px;
}

.settingsContent>div {
    display: table-row;
}

.settingsContent>div>div {
    display: table-cell;
}

.settingsVolumeSlider {
    margin-left: 10px;
    margin-top: 5px;
}

.settingsVolumeButtonFull {
    margin-left: 15px;
}
