.colorBox {
    width: calc(var(--player-avatar-size) * 1.125);
    height: calc(var(--player-avatar-size) * 1.125);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.avatar {
    max-height: var(--player-avatar-size);
    max-width: var(--player-avatar-size);
}

.isNotAlive {
    opacity: 50%;
}

.turn.medium {
    --player-avatar-size: 42px;
}

.turn {
    width: calc(var(--player-avatar-size) * 1.25);
    height: calc(var(--player-avatar-size) * 1.25);

    border: calc(var(--player-avatar-size) * 0.08) black solid;
    box-shadow: 0 0 10px black;
}

:global(.bp5-dark) .turn {
    border-color: white;
}

.small {
    --player-avatar-size: 16px;
}

.medium {
    --player-avatar-size: 32px;
}

.emptySeat {
    border: 2px black dashed;
}

:global(.bp5-dark) .emptySeat {
    border-color: white;
}
