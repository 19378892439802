.entry {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 4px;
    padding: 6px;
    margin: 5px;
}

:global(.bp5-dark) .entry {
    border-color: white;
}

.infoBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    gap: 5px;
}

.hash {
    flex-basis: 6em;
    width: 6em;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-start;
}

.date {
    align-self: flex-end;
    text-align: right;
}

.author {
    flex-grow: 1;
    align-self: center;
    text-align: center;
}

.subject, .body {
    flex-grow: 1;
    align-self: flex-start;
}
