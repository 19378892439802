.indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.indicator-error,
.indicator-error>a {
    color: red;
}

.indicator-error-dark,
.indicator-error-dark>a {
    color: red;
}

.indicator-connecting,
.indicator-connecting>a {
    color: yellow;
}

.indicator-connecting-dark,
.indicator-connecting-dark>a {
    color: yellow;
}

.indicator-offline,
.indicator-offline>a {
    color: gray;
}

.indicator-offline-dark,
.indicator-offline-dark>a {
    color: lightgray;
}

.indicator-online,
.indicator-online>a {
    color: green;
}

.indicator-online-dark,
.indicator-online-dark>a {
    color: lightgreen;
}

.indicator-ingame,
.indicator-ingame>a {
    color: green;
}

.indicator-ingame-dark,
.indicator-ingame-dark>a {
    color: lightgreen;
}
