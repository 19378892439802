@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

body,
html,
#__next {
    padding: 0px;
    margin: 0px;
    --text-color: #1c2127;
    --table-header-background-color: #8ABBFF;
    --table-subheader-background-color: #4C90F0;
}

body:has(.bp5-dark-body),
.bp5-dark {
    background-color: #2f343c;
    --text-color: #f6f7f9;
    --table-header-background-color: #184A90;
    --table-subheader-background-color: #215DB0;
}

#main {
    display: flex;
    max-width: 100vw;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;

    /* fallback */
    height: calc(100vh - 100px);
    /* use smallest viewport height for mobile browsers to account for address bar */
    height: calc(100svh - 100px);
}

#content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100vw;
}

h1 {
    font-size: 2em;
    font-weight: normal;
}

h2 {
    font-size: 1.8em;
    font-weight: normal;
}

:root {
    --map-max-width: calc(100vw - 43vmin);

    /**
     * 50px for top header
     * 30px for margin-top of the map
     * 20px for padding on game drawer
     * 75px drawer contents
     * 10px extra buffer
    */
    --map-max-height: calc(100vh - 50px - 30px - 20px - 75px - 10px);

    --large-font-size: 1.8em;
}

@media screen and (min-device-width: 640px) {

    /* desktop, basically */
    h1 {
        font-size: 1.6em;
    }

    h2 {
        font-size: 1.4em;
    }

    :root {
        --large-font-size: 2em;
    }

}

@media screen and (max-device-height: 480px) {

    /* VERY short screens such as landscape phone */
    h1 {
        font-size: 1em;
    }

    h2 {
        font-size: 0.8em;
    }

}

.emoji {
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", "Android Emoji", "EmojiOne Mozilla", "Twemoji Mozilla", "Noto Emoji", "Segoe UI Symbol", EmojiSymbols, emoji;
}

/**
 * for @uiw/react-color
 **/
 .bp5-dark .w-color-sketch {
    --sketch-background: #323232 !important;
}

.bp5-dark .w-color-swatch {
    --sketch-swatch-border-top: 1px solid #525252 !important;
}

.bp5-dark .w-color-block {
    --block-background-color: #323232 !important;
    --block-box-shadow: rgb(0 0 0 / 10%) 0 1px !important;
}

.bp5-dark .w-color-editable-input {
    --editable-input-label-color: #757575 !important;
    --editable-input-box-shadow: #616161 0px 0px 0px 1px inset !important;
    --editable-input-color: #bbb !important;
}

.bp5-dark .w-color-github {
    --github-border: 1px solid rgba(0, 0, 0, 0.2) !important;
    --github-background-color: #323232 !important;
    --github-box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px !important;
    --github-arrow-border-color: rgba(0, 0, 0, 0.15) !important;
}

.bp5-dark .w-color-compact {
    --compact-background-color: #323232 !important;
}

.bp5-dark .w-color-material {
    --material-background-color: #323232 !important;
    --material-border-bottom-color: #707070 !important;
}

.bp5-dark .w-color-alpha {
    --alpha-pointer-background-color: #6a6a6a !important;
    --alpha-pointer-box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px !important;
}

/* this is for the file upload button */
.file-upload-button:target {
    background-color: #dce0e5;
    box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
    color: #1c2127;
}

.bp5-dark .file-upload-button:target {
    background-color: #1c2127;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
    color: #f6f7f9;
}
