.body {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
}

.name {
    font-size: large;
    font-weight: bold;
}

.statsTable {
    display: table;
    margin-top: 10px;
}

.statsTableRow {
    display: table-row;
}

.statsTableRowName {
    font-weight: bold;
    padding-right: 5px;
}

.statsTableRowName,
.statsTableRowValue {
    display: table-cell
}

.sectionHeader {
    margin-top: 20px;
    font-size: large;
    font-weight: bold;
}
